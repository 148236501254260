@import "../../styles/Colors.scss";

.collection_page_wrapper {
  margin: 0px !important;
  padding: 0px !important;

  .infinite-scroll-component {
    // overflow-y: hidden !important;
    // height: 100%;
  }

  .collection_page_outer {
    padding: 0px 20px 20px 20px;
    max-width: 1366px;
    margin: 0 auto;
    // .breadCrumbs {
    //   align-items: center;

    //   .ant-breadcrumb {
    //     .ant-breadcrumb-link {
    //       a {
    //         color: #ababab !important;
    //       }
    //     }

    //     li:last-child {
    //       .ant-breadcrumb-link {
    //         a {
    //           color: $black !important;
    //         }
    //       }
    //     }
    //   }

    //   // background-color: #f8f8f8;

    //   display: flex;
    //   justify-content: space-between;
    //   margin-bottom: 10px;
    //   padding-bottom: 10px;
    //   width: 100%;
    // }
  }

  .collection_page_content {
    padding: 10px 20px;

    .header_border {
      border-bottom: 1px solid #edebef;
      //margin-bottom: 10px;

      span {
        color: $secondaryTwo;
        font-size: 12px;
      }
    }

    .mobile_header_border {
      border: 1px solid #edebef;
    }

    .ant-row {
      padding: 10px 0px;
      z-index: 2;
    }

    h4 {
      margin: 0px !important;
      font-size: 18px;
      font-weight: 300;
      color: $black;
    }

    .grid-show-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: $secondaryTwo;
        font-size: 12px;
      }

      .grid-icons-container {
        .ant-space-item {
          height: 20px;

          img {
            background: #f2f3f7 !important;
            cursor: pointer;
          }
        }
      }

      .ant-space {
        align-items: center;
      }
    }

    .filter_btn {
      background-color: #f2f3f7;
      padding: 5px 15px;
      border: none;
      //pointer-events: none;
      margin: 0px;
      opacity: 100;

      span {
        font-size: 14px;
        padding-left: 5px;
        // font-weight: 500;
        color: $filterOptionText;
      }

      svg {
        color: #000000;
        font-size: 10px;
      }
    }

    .ant-btn {
      &:hover,
      &:active,
      &:focus {
        background-color: #f2f3f7;
        outline: none;
        opacity: 80;
        color: #000000;
      }
    }

    .description_container {
      text-align: center;
      width: 100%;
      padding: 10px 0px;
      // display: flex;
      // justify-content: flex-start;

      span {
        color: $filterOptionText;

        .primary_red_color {
          color: $primary_red;
          font-weight: 600;
          cursor: pointer;
          font-size: 14px;
          font-family: "Inter-Light" !important;
          font-weight: 100 !important;
        }
      }
    }

    .mobile_description_container {
      padding: 10px 20px;
      color: #000;
    }

    .products_count_tag {
      color: $filterOptionText;
      font-size: 12px;
      font-weight: 100;
      font-family: "Inter-Regular" !important;

      span {
        color: $black;
        font-weight: 100;
      }
    }

    .load-more-btn {
      border-color: #ff0d50 !important;
      color: #ff0d50 !important;
      font-weight: 500;
      border-radius: 5px;
      padding: 0px 100px;
    }

    button {
      border-radius: 0px;
    }

    .ant-select-selector {
      // height: 24px !important;

      .ant-select-selection-item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #1e1e1e;

        // font-family: "Poppins-SemiBold";
        // font-weight: 600;
      }
    }

    .sort_select_option {
      .ant-select-selector {
        .ant-select-selection-item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }

    .mobile_select_option {
      .ant-select-selector {
        background-color: $background_color;
      }
    }
  }

  // .ant-select-selector {
  //   height: 10px;
  //   padding: 0px 6px !important;
  //   .ant-select-selection-item {
  //     font-size: 11px;
  //     padding: 0px !important;
  //   }
  //   .ant-select-arrow {
  //     svg {
  //       font-size: 11px !important;
  //     }
  //   }
  // }

  .product_listing_container {
    // min-height: 1600px;
    margin-bottom: 30px;
    padding: 20px 0px;
    justify-content: center;

    // .ProductCard,
    // .ProductHorizontalCard {
    //   padding: 10px !important;
    // }
    // gap: 20px;
    gap: 1%;
    // justify-content: space-between;
    // .grid5-product-card {
    //   .ant-card-meta-description {
    //     .ant-space-item {
    //       gap: 10px;
    //       .ant-typograph,
    //       .product_price {
    //         font-size: 13px !important;
    //       }
    //       del {
    //         font-size: 10px !important;
    //       }

    //       .ant-tag {
    //         padding: 0px 4px 0px 4px;
    //         margin: 0px;

    //         span {
    //           font-size: 10px;
    //           margin: 0px;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  // .mobile_product_listing_container {
  //   .ProductCard,
  //   .ProductHorizontalCard {
  //     padding-right: 0px !important;
  //     padding-left: 0px !important;
  //   }
  // }
  .pagination_container {
    // margin-bottom: 30px;
    padding-top: 20px;

    .ant-pagination {
      li {
        background-color: #e4e4e4;
        border-radius: 0px;

        a,
        svg {
          color: #1e1e1e;
          font-size: 16px;
        }
      }

      // li:hover {
      //   background-color: #1e1e1e;
      //   color:$white
      // }
    }

    li.ant-pagination-item-active {
      border-color: #1e1e1e;
    }

    .ant-select {
      display: none;
    }

    button.ant-pagination-item-link {
      border-radius: 0px;
    }
  }

  .pagination_container_mobile {
    background-color: #ffffff;

    li {
      a,
      svg {
        font-size: 12px !important;
      }
    }
  }
}

.collection_page_wrapper {
  .ant-row {
    padding: 0px;
  }
}

// mz css
// .seo-long-description {
//   text-align: center;
//   padding: 40px 0px;
//   max-height: 400px;
//   overflow-y: scroll;
// }

body .collection-title {
  font-size: 18px;
  margin: 0;
  text-transform: capitalize;
  font-family: "Inter-Regular" !important;
  font-weight: 100 !important;
}
.express-delivery-outer {
  display: flex;

  .expressDelivery_tag {
    background-color: $expressDeliveryTagBg;
    color: $secondaryTwo;
    margin: 0px 5px;
    padding-bottom: 2px;

    .ant-typography {
      font-size: 11px;
      font-family: "Inter-Regular" !important;
    }
  }
}

body .product-title {
  margin: 0;
  font-size: inherit;
  // font-weight: inherit;
  line-height: inherit;
  font-family: "Inter-Regular" !important;
  font-weight: 100 !important;
}

.collection-banner {
  padding-bottom: 20px;
}

.collection-banner img {
  width: 100%;
  object-fit: cover;
}

body .main-collection-inner {
  flex-direction: column;
}

.landing-page-content {
  max-width: 100%;
}

.landing-page-content.Homepage {
  min-height: auto !important;
}
// SEO style
.seo-long-description-container {
  text-align: center;
  padding: 40px 0;
}

.seo-long-description-content {
  transition: max-height 0.3s ease;
}

.seo-long-description-content.collapsed {
  max-height: 400px;
  overflow: hidden;
}

.seo-long-description-content:not(.collapsed) {
  max-height: none;
}

.see-more-btn {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 14px;
  background-color: rgb(236, 36, 143);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.see-more-btn:hover {
  background-color: rgb(192, 14, 109);
}

@media (max-width: 767px) {
  .collection_page_wrapper .pagination_container_mobile {
    padding-bottom: 20px !important;
  }

  // .seo-long-description {
  //   text-align: center;
  //   padding: 40px 0px;
  //   max-height: 300px;
  //   overflow-y: scroll;
  // }
}
@media (max-width: 1366px) {
  .landing-page-content.Homepage {
    max-width: 100% !important;
  }
}
