.share-icon-popup-style {
    position: absolute;
    top: -8px;
    width: 120px;
    right: 30px;
    z-index: 1;
    border-radius: 3px;
    padding: 5px 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: #fff;
}

.copied-text-dialog {
    font-size: 13px;

}

.small-icon {
    font-size: 18px !important;
}

.ProductPage .ProductDetailPanel .MobileQuantityPicker.MobileQuantityPickerSoldOut .ant-input-number-wrapper .ant-input-number {
    width: 35px !important;
    margin: 0 10px !important;
    border: 1px solid #e2e5f1;
    border-radius: 8px !important;
}

.ProductPage .ProductDetailPanel .price.priceItemMobile {
    font-size: 16px !important;
}

.mobilePriceRed {
    color: #ff0d50 !important
}

@keyframes addToCartAnimation {
    9% {
        background-image: url("../../../../assets/images/addToCartAnimation/animation-1.png");
    }

    18% {
        background-image: url("../../../../assets/images/addToCartAnimation/animation-2.png");
    }

    27% {
        background-image: url("../../../../assets/images/addToCartAnimation/animation-3.png");
    }

    36% {
        background-image: url("../../../../assets/images/addToCartAnimation/animation-4.png");
    }

    45% {
        background-image: url("../../../../assets/images/addToCartAnimation/animation-5.png");
    }

    54% {
        background-image: url("../../../../assets/images/addToCartAnimation/animation-6.png");
    }

    63% {
        background-image: url("../../../../assets/images/addToCartAnimation/animation-7.png");
    }

    72% {
        background-image: url("../../../../assets/images/addToCartAnimation/animation-8.png");
    }

    81% {
        background-image: url("../../../../assets/images/addToCartAnimation/animation-9.png");
    }

    90% {
        background-image: url("../../../../assets/images/addToCartAnimation/animation-10.png");
    }

    100% {
        background-image: url("../../../../assets/images/addToCartAnimation/animation-11.png");
    }
}

body .add-to-cart-button-animation {
    // background-color: red;
    // background-color: #E72744;
    background-image: url("../../../../assets/images/addToCartAnimation/animation-1.png");
    animation-name: addToCartAnimation;
    animation-timing-function: ease-in;
    animation-duration: 2s;
    height: 37px;
    border-radius: 3px;
    width: 137px;
    background-size: contain;
    background-repeat: no-repeat;
}