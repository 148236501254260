.spinner-redo-white {
    color: white;
    svg {
        fill: white !important;
        color: white !important;
    }
}
.product-size-bottom-drawer-main {
    position: absolute;
    bottom: 0;
    background-color: white;
    border: 1px solid #ddd;
    width: 100%;

.drawer-content {
    padding: 0 24px;
    font-family: 'Inter-Regular';
}

.title-and-close-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.custom-radio-group {
    display: flex;
    width: 100%;
    justify-content: center;
    overflow-x: auto;
}

.option {
    margin-right: 10px;
    width: 53px;
    height: 53px;
    border: none;
    background-color: #fff;
    cursor: pointer;
    border: 1px solid #5a5a5a;
    border-radius: 50%;
    color: #5a5a5a;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    line-height: 12.1px;
    /* Adjusted for vertical center alignment */
}

 .button-sold-out-drawer {
     background:
         linear-gradient(to bottom, transparent 47%, #b2b4b9 51%, #b2b4b9 50%, transparent 50%);
     color: #dedede;
 }

.button.selected {
    background-color: #ff0d50;
    color: #fff;
    border: none;
}

 .centered-button {
     margin-top: 20px;
     text-align: center;
     margin-bottom: 5px;
 }
 }