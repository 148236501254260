// Color Pallete 🖌

@import "../../../../../styles/Colors.scss";

///////////////////////////////////////////

.ProductHorizontalCard {
  .ant-card {
    display: flex;
    border-radius: 0px;

    .ant-card-body {
      // padding: 5px 10px;

      .ant-card-meta-title {
        text-overflow: revert;
        overflow: revert;
        white-space: revert;

        .product_title {
          color: $productTitle;
          line-height: 16px;
          height: 35px;
          display: block;
          overflow: hidden;
        }
      }
    }

    .ant-card-cover {
      width: 35%;

      .ant-image {
        img {
          border-radius: 0px;
        }
      }

      .outOfStock_container {
        position: absolute;
        z-index: 1;
        top: 45%;
        // left: 0px;
        width: 100%;

        .outOfStock_tag {
          background-color: $outOfStockTagBg;
          font-size: 16px;
          color: $surface;
          width: 100%;
          display: block;
          font-family: "Inter-Medium" !important;
          font-weight: 100 !important;
          height: 30px;
        }
      }

      .mobile_outOfStock_container {
        .outOfStock_tag {
          background-color: $outOfStockTagMobileBg;
          height: 20px;
        }
      }
    }

    .mobile_discount_tag {
      background-color: $discountTagColor !important;

      border-radius: 100px;

      .ant-typography {
        color: $white;
        font-size: 10px;
        // font-weight: 600;
      }
    }

    .ant-space {
      gap: 10px !important;

      .product_price {
        // opacity: 1;
        // font-size: 12px;
        padding-top: 5px;

        span {
          color: $black;
          font-size: 16px;
          // font-weight: 600;
          font-family: "Poppins-Medium" !important;
        }
      }

      .product_tags_container {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: thin;
        scrollbar-color: var(--scrollbar-color)
          var(--scrollbar-background-color);

        .onlineOnly_tag {
          background-color: $onlineOnlyTagBg;
          color: $secondaryTwo;

          .ant-typography {
            font-size: 11px;
            font-family: "Inter-Medium" !important;
            font-weight: 100 !important;
          }
        }

        .freeShipping_tag {
          background-color: $freeShippingTagBg;
          color: $secondaryTwo;

          .ant-typography {
            font-size: 11px;
            font-family: "Inter-Medium" !important;
            font-weight: 100 !important;
          }
        }

        .expressDelivery_tag {
          background-color: $expressDeliveryTagBg;
          color: $secondaryTwo;
          padding-bottom: 2px;

          .ant-typography {
            font-size: 11px;
            font-family: "Inter-Medium" !important;
            font-weight: 100 !important;
          }
        }
      }
    }
  }
}
