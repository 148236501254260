@import "../../styles/Colors.scss";

// mobile bottom nav should be hidden on collection page

body.hide-mobile-bottom-nav .mobile-bottom-nav{
  display: none;
}

.mobile-bottom-nav {
  display: none;
}



.mobile-filters-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 1;
  height: 50px;

  .ant-col {
    gap: 4px;
  }

  .ant-col:first-child {
    border-right: 1px solid #0000004d;
  }

  span {
    color: #535766;
    padding-left: 4px;
  }
}

.mobile-filters-dropdown {
  width: 100%;
  padding: 0px !important;
  margin-top: 40px !important;

  // textAlign: "center",
  //padding: "10px 0px 0px 0px",
  .ant-dropdown-menu {
    border-radius: 30px 30px 0px 0px !important;
    padding: 0px !important;

    .ant-dropdown-menu-item {
      padding: 0px !important;

      .mobile-sort-header {
        border-bottom: 1px solid #f2f3f7;

        p {
          //font-family: Lato;
          // font-weight: 700px
          font-size: 16px;
        }
        svg {
          font-size: 22px;
          position: absolute;
          right: 10px;
        }
      }
      .mobile-sort-list {
        padding: 0px;
        ul {
          li {
            padding: 10px 0px !important;
            img {
              margin-left: 20px;
            }
            .ant-typography {
              padding: 0px 20px;
            }
          }

          .selected-sort-item {
            border-left: 2px solid $primaryOne;
          }
        }
      }
    }
  }
}

.ant-dropdown-menu-item-active {
  background-color: #ffffff !important;
}
body .ant-dropdown-trigger.sort-by-mobile {
  width: 50%;
  border-right: 1px solid rgba(0, 0, 0, 0.3019607843);
}