.ImagesCarousel {
  .ant-carousel {
    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            margin-right: 10px !important;
          }
        }
      }
    }
  }
}
