@import "../../styles/Colors.scss";

.mobile-filters-drawer {
  .ant-drawer-wrapper-body {
    .ant-drawer-header {
      button {
        position: absolute;
        right: 0px;
      }
    }
    .ant-drawer-body {
      padding: 0px;
      .mobile-filters-wrapper {
        height: 100%;
        padding: 0px;
        .filter-tabs {
          background-color: $background;
          min-height: 520px;
          .ant-col {
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #e5e5e5;
            .ant-typography {
              text-transform: capitalize;
              font-size: 16px;
            }
          }
          .selected-tab {
            background-color: $surface;
            .ant-typography {
              color: $primaryOne;
              font-family: "Poppins-Medium";
            }

            .selected-filters-count {
              font-size: 12px !important;
              padding-left: 2px;
              font-family: "Poppins-Medium";
            }
          }
        }

        .filter-options-container {
          padding: 10px;


          .reset-filters-text {
            font-size: 12px;
            color: #707070;
            text-decoration: underline;
            float: right;
            padding-bottom: 10px;
            display: none;
          }

          .checkbox-container {
            .ant-checkbox-wrapper {
              //  float: right;
              width: 100%;

              .ant-checkbox {
                position: absolute;
                right: 0px;
              }

              .ant-typography {
                color: $secondaryOne;
              }
            }

            .checkbox-checked {
              color: $primaryOne;
            }
          }
        }

        .ant-input-search {
          margin-bottom: 10px;

          button {
            border-left: 0px;
          }
        }
      }
    }
    .filter-buttons-container {
      position: absolute;
      bottom: 0px;
      width: 100%;

      .ant-col {
        button {
          width: 100%;
          height: 40px;
          border-radius: 0px;
        }
      }
      .reset-button-container {
        button {
          background-color: $surface;
          color: #707070;
        }
      }
      .apply-button-container {
        button {
          background-color: $primaryThree;
          color: $surface;
        }
      }
    }
  }
}

// Mz Css
.ant-drawer-close svg {
  color: #000;
}
.price-mobile-container .price-header {
  justify-content: flex-end;
  margin-bottom: 30px;
}
body .ant-slider-track {
  background-color: #ff406b !important;
}
.price-filter input {
  border: unset;
}
.mobile-filters-drawer .ant-drawer-body .price-filter input {
  border-radius: 5px !important;
}
.selected-sort-item span {
  color: #ff0d50;
}
.filter-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1024px) {
  .mobile-filters-drawer .ant-drawer-wrapper-body .ant-drawer-body .mobile-filters-wrapper .filter-options-container {
    padding-bottom: 50px;
  }
}