@import "../../styles/Colors.scss";
// .ant-tabs.ant-tabs-top .ant-tabs-nav .ant-tabs-nav-wrap {
//   // justify-content: space-around !important;
//   }
.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 25px !important;
}
.ProductPage {
  .breadCrumbs {
    span {
      font-size: 14px !important;
      font-family: "Inter-Light" !important;
      font-weight: 100 !important;
    }
  }

  .breadCrumbsMobile {
    span {
      font-size: 12px !important;
      font-family: "Inter-Light" !important;
      font-weight: 100 !important;
    }
  }

  .ProductImagesPanel {
    .ImagesGrid {
      .ImgContainer {
        overflow: "hidden";
        position: "relative";
        width: 100%;
        height: 100%;

        // img:hover {
        //   transform: scale(1.02);
        //   transition: transform 0.3s ease;
        //   overflow: "hidden";
        // }
      }

      .ImgContainer img {
        display: block;
        width: 100%;
        height: 100%;
        transition: transform 0.3s ease;
      }
      .ImgContainer:hover img {
        transform: scale(1.02);
        // transition: transform 0.3s ease;
      }
    }
  }

  .ProductDetailPanel {
    // padding-left: 20px;
    .title {
      opacity: 1;
      letter-spacing: 0px;
      // font-size: 20px !important;
      color: $secondaryTwo !important;
      font-family: "Inter-Medium" !important;
      font-weight: 100 !important;
    }

    .secondary {
      font-weight: 600;
      font-size: 14px !important;
      color: $text_light !important;
    }

    .secondaryDark {
      font-size: 14px !important;
      color: $secondaryOne !important;
    }

    .price {
      font-weight: 100;
      font-size: 18px !important;
      color: $primary_red !important;
      font-family: "Inter-Medium" !important;
    }

    .priceCut {
      color: #606060;
      letter-spacing: 0px;
      font-size: 16px !important;
      text-decoration: line-through;
      font-family: "Inter-Light" !important;
      font-weight: 100 !important;
    }

    .discountTag {
      height: 23px;
      font-size: 16px;
      color: #ea462f;
      text-align: center;
      letter-spacing: 0px;
      font-family: "Inter-Regular" !important;
      font-weight: 100;
      background: #ffeff1 0% 0% no-repeat padding-box;
    }

    .cyan {
      background: $cyan;
      border-radius: 16px;
      opacity: 1;
      padding: 6px 18px;
    }

    .yellowish_orange {
      background: $yellowish_orange;
      border-radius: 16px;
      opacity: 1;
      padding: 6px 18px;
    }

    .soft_peach {
      background: $soft_peach;
      border-radius: 16px;
      opacity: 1;
      padding: 6px 18px;
    }

    .QuantityPicker {
      .ant-input-number-wrapper {
        height: 40px !important;

        .ant-input-number-group-addon {
          width: 28px !important;
          height: 28px !important;
          border: none !important;
          border-radius: 0px !important;
          background: $background !important;
        }
        .ant-input-number {
          width: 85px !important;
          margin: 0 10px !important;
          border: none !important;
          border-radius: 0px !important;
          // display: block !important;

          input {
            height: 40px !important;
            text-align: center !important;
            border-radius: 0px !important;
            background: $background !important;
          }
        }
      }
    }

    .MobileQuantityPicker {
      .ant-input-number-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-input-number-group-addon {
          width: 28px !important;
          height: 28px !important;
          border: none !important;
          border-radius: 25px !important;
          background: #edf0f0 !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .ant-input-number {
          width: 70px !important;
          margin: 0 10px !important;
          border: 1px solid $border;
          border-radius: 8px !important;
          input {
            text-align: center !important;
          }
        }
      }
    }

    .wishList_btn {
      background: $peach_red;
      border-radius: 16px;
      color: $black;
      border: none;
      width: auto;

      svg {
        color: $primary_red;
      }
    }

    .share_btn {
      background-color: $background_color;
      border-radius: 16px;
      color: $black;
      border: none;
    }

    .ProductOptions {
      .AddonOptions {
        padding: 10px !important;
      }
    }

    .ProductDescription {
      .ant-tabs-tab {
        span {
          // font-size: 16px !important;
          color: #b7b7b7 !important;
        }
      }
      .ant-tabs-tab-active {
        span {
          color: $black !important;
          // font-size: 16px !important;
        }
      }

      .ant-table-container {
        border-radius: 0px !important;
        td.ant-table-cell {
          padding: 10px 10px !important;
        }
        td:first-child {
          // width: 65px !important;
          // display: block !important;
          width: max-content !important;
        }
      }
    }
  }

  .ant-radio-button-wrapper {
    color: #404553 !important;
    border: 1px solid #e2e5f1 !important;
  }

  .ant-radio-button-wrapper-checked {
    color: #ff3f6c !important;
    background: none !important;
    border: 1px solid #ff3f6c !important;
  }
}
.overflowy-auto {
  overflow-y: auto !important;
}

// Mz css
.sizechart-container .table-body > tr > td.radio-column {
  display: none;
}
.Homepage {
  max-width: 1366px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.product-buying-bottom-nav > div:last-child {
  margin-bottom: 5px !important;
}

body .product-tabs .ant-tabs-content-holder span.ant-typography {
  font-family: "Inter-Light" !important;
  font-weight: 100 !important;
}
.ProductPage .ProductDetailPanel .ProductDescription span {
  font-family: "Inter-Regular" !important;
  font-weight: 100;
}
@media (min-width: 1024px) and (max-width: 1100px) {
  .Homepage {
    max-width: 1024 !important;
  }
}

@media (min-width: 1101px) and (max-width: 1200px) {
  .Homepage {
    max-width: 1100 !important;
  }
}

@media (min-width: 1201) and (max-width: 1300px) {
  .Homepage {
    max-width: 1200 !important;
  }
}

@media (min-width: 1301) and (max-width: 1400px) {
  .Homepage {
    max-width: 1300 !important;
  }
}

@media (min-width: 1401) {
  .Homepage {
    max-width: 1366 !important;
  }
}

@media (max-width: 1024px) {
  body .size-option-container {
    max-width: 100%;
    flex-wrap: wrap;
  }
  body .product-buying-bottom-nav > div:first-child {
    flex-wrap: nowrap;
  }
  body .dispatch-container {
    align-items: center;
    display: flex;
  }
  .ant-btn-primary > span:last-child {
    font-size: 12px;
  }
}
