.spinner-redo-white {
    color: white;
    svg {
        fill: white !important;
        color: white !important;
       
    }
}
.SizeChartDrawer {
    z-index: 2000 !important;
    background-color: #E4E4E4!important;
    .ant-drawer-header{
        background-color: white;
    }
    .ant-drawer-title{
        color: #000000 !important;
        font-family: "Inter-Regular" !important;
    }
    .ant-drawer-body{
        padding: 0px !important;
    }
    .anticon{
       color: #929292
    }

}
.border-line-20{
    border-top: 10px solid #F5F5F6;;
}